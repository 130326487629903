import React from "react";

const BlogItem = ({ title, author, date, description, slug, category }) => {
  return (
    <div>
      <div>
        <a className="hidden inline-block">
          <span className="border-b border-gray-200 inline-flex items-center text-md font-medium leading-5 text-lime-600">
            {category}
          </span>
        </a>
      </div>
      <a href={slug} className="block pt-2 ">
        <h3 className="mt-2 mb-4 text-2xl leading-7 font-semibold text-gray-900 hover:text-gray-500 hover:transition ease-in-out duration-200">
          {title}
        </h3>
        <p className="mt-3 pr-20 text-base leading-6 text-gray-500">
          {description}
        </p>
      </a>
      <div className="mt-4 flex items-center">
        <div className="">
          <div className="flex text-md leading-5 font-semibold text-gray-500">
            <time datetime="2020-03-16">{date}</time>
          </div>
        </div>
        <div className="ml-12">
          <p className="text-md font-medium text-blue-500 ">
            <a href={slug}>Read More...</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
